<template>
    <div class='header-left-section flex align-items-center justify-content-start mb-3 md:mb-0'>
        <div class='profile relative'>
            <a :href="'http://image.a1catering.com.tr/'+dataItem.employeePhoto" target="_blank">
                <img v-if='dataItem.employeePhoto != null && dataItem.employeePhoto != ""' :src="'http://image.a1catering.com.tr/'+dataItem.employeePhoto" width='148' height='148' class='border-circle border-3 border-solid' preview />
            </a>
        </div>

        <div class='ml-2 md:mb-0'>
            <span class='text-4xl line-height-1 font-bold block mb-1'>{{ dataItem.name }} {{ dataItem.surName }}</span>	
            <span class='block subtext mb-3 text-green-600 font-bold'>{{ dataItem.activeProject != null ? dataItem.activeProject.name : " Projeye Ekli Değil" }}</span>
            <span class='block subtext'>{{ dataItem.gender }} - {{ formatDate(dataItem.birthday) }}</span>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'ProjectEmployeeLeftPanel',
    props: {
    dataItem: {},
    },
    data() {
        return {
            birthDay: '',
        };
    },
    mounted() {

    },
    methods : {
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
    }

};
</script>

<template>
    <TabMenu :model='nestedRouteItems' class='mb-5' />
</template>
<script>

export default {
    name: 'ProjectEmployeeTabMenu',
    props: {
        employeeId: {},
        projectEmployeeId: {}
    },
    data() {
        return {
            nestedRouteItems: [],
        };
    },
    created() {
        this.nestedRouteItems = [
            {
                label: 'Temel Bilgiler',
                to: '/ProjectEmployee/Edit/' + this.projectEmployeeId + '/' + this.employeeId,
            },
            {
                label: 'Kişisel Bilgiler',
                to: '/ProjectEmployee/Information/'+ this.projectEmployeeId + '/' + this.employeeId,
            },
            {
                label: 'İletişim Bilgileri',
                to: '/ProjectEmployee/Contact/'+ this.projectEmployeeId + '/' + this.employeeId,
            },
            {
                label: 'Ödeme Bilgileri',
                to: '/ProjectEmployee/Payment/'+ this.projectEmployeeId + '/' + this.employeeId,
            },
            {
                label: 'Çalışma Durumu',
                to: '/ProjectEmployee/WorkStatus/'+ this.projectEmployeeId + '/' + this.employeeId,
            },
            {
                label: 'Dosyalar',
                to: '/ProjectEmployee/Files/'+ this.projectEmployeeId + '/' + this.employeeId,
            },
            {
                label: 'Mesai',
                to: '/ProjectEmployee/Overtime/'+ this.projectEmployeeId + '/' + this.employeeId,
            },
            {
                label: 'İzin',
                to: '/ProjectEmployee/DayOff/'+ this.projectEmployeeId + '/' + this.employeeId,
            },
            {
                label: 'Avans',
                to: '/ProjectEmployee/Allowance/'+ this.projectEmployeeId + '/' + this.employeeId,
            }, {
                label: 'Maaşlar',
                to: '/ProjectEmployee/Salary/'+ this.projectEmployeeId + '/' + this.employeeId,
            },
            {
                label: 'Maaş Geçmişi',
                to: '/ProjectEmployee/SalaryHistory/'+ this.projectEmployeeId + '/' + this.employeeId,
            },
            {
                label: 'Prim',
                to: '/ProjectEmployee/Bonus/'+ this.projectEmployeeId + '/' + this.employeeId,
            },

        ];
    },
};
</script>
<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-employee-left-panel :data-item='employee'></project-employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Maaş Geçmişi</h3>

					<p class="text-lg">
                        Personele ait maaş değişikliklerini bu bölümden görüntüleyebilirsiniz.
                        <br>
					</p>

				</Sidebar>

				</span>

            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <project-employee-tab-menu :employee-id='employeeId' :project-employee-id='projectEmployeeId'></project-employee-tab-menu>

                <div class='col-12'>

                    <DataTable :value='salaryHistory' class='p-datatable-gridlines' :rows='10' dataKey='id' :rowHover='true' :paginator='true' :loading='loadingIndicator' responsiveLayout='scroll'>

                        <template #empty>
                            Maaş Kaydı Bulunamadı.
                        </template>

                        <template #loading>
                            Maaş Geçmişi yükleniyor. Lütfen bekleyin.
                        </template>

                        <Column header='Tarih' style='width:13rem'>
                            <template #body='slotProps'>
                                {{ formatDate(slotProps.data.insertDate)}}
                            </template>
                        </Column>

                        <Column header='Maaş' style='width:9rem'>
                            <template #body='{data}'>
                                {{ formatPrice(data.officialSalary) }} &#8378;
                            </template>
                        </Column>

                        <Column header='Diğer Maaş' style='width:9rem'>
                            <template #body='{data}'>
                                {{ formatPrice(data.unOfficialSalary) }} &#8378;
                            </template>
                        </Column>

                        <Column header='Toplam Maaş' style='width:9rem'>
                            <template #body='{data}'>
                                {{ formatPrice(data.unOfficialSalary + data.officialSalary) }} &#8378;
                            </template>
                        </Column>

                        <Column header='Banka' style='width:12rem'>
                            <template #body='{data}'>
                                {{ data.bankName }}
                            </template>
                        </Column>

                        <Column header='IBAN' style='width:14rem'>
                            <template #body='{data}'>
                                {{data.iban }}
                            </template>
                        </Column>
                        <Column header='Değişikliği Yapan' style='width:16rem'>
                            <template #body='{data}'>
                                {{ data.managerUser?.name + " " + data.managerUser?.surName}}
                            </template>
                        </Column>

                        <Column header='Onaylayan ' style='width:16rem'>
                            <template #body='{data}'>
                                {{ data.isApproved ?  (data.approveManagerUser?.name + " " + data.approveManagerUser?.surName) : " - "}}
                            </template>
                        </Column>

                        <Column header='Onay Tarihi ' style='width:16rem'>
                            <template #body='{data}'>
                                {{ data.isApproved ?  (formatDate(data.approveDate)) : " - "}}
                            </template>
                        </Column>
                        <Column field='verified' header='Maaş Değişim Onayı' bodyClass='text-center' style='width:7rem'>
                            <template #body='{data}'>
                                <i class='pi' style='font-size:2rem;'
                                   :class="{'text-green-500 pi-check-circle': data.isApproved, 'text-pink-500 pi-times-circle': !data.isApproved}"></i>
                            </template>
                        </Column>
                    </DataTable>

                </div>

            </div>

        </div>

    </div>
</template>


<script>


import { checkActiveProjectIdIsValid, getActiveProjectId } from '../common/commonConstantFunctions';
import moment from 'moment';
import AdminToast from '../../components/shared/toast/adminToast';
import ProjectEmployeeLeftPanel from './projectEmployeeLeftPanel';
import ProjectEmployeeTabMenu from './projectEmployeeTabMenu';
import EmployeeSalaryService from '../../services/employeeSalaryService';
import { getEmployee, getProjectEmployee } from '../common/commonFunctions';

export default {
    components: { ProjectEmployeeTabMenu, ProjectEmployeeLeftPanel, AdminToast },
    _employeeSalaryService: null,

    async created() {

        this._employeeSalaryService = new EmployeeSalaryService();
        this.projectId = getActiveProjectId();
        this.employeeId = this.$route.params.employeeId;
        this.projectEmployeeId = this.$route.params.projectEmployeeId;
        this.employee = await getEmployee(this.employeeId);
    },

    async mounted() {
        await this.getEmployeeSalaryChangesHistory();
        this.projectEmployee = await getProjectEmployee(this.projectEmployeeId);
        checkActiveProjectIdIsValid(this, this.projectEmployee.projectId);
    },
    data() {

        return {
            selectedSalaryDate: null,
            year: 1900,
            month: 1,
            loadingIndicator: false,
            salaryHistory: [],
            projectEmployeeId:0,
            projectId:0,
            employeeId:0,
            employee:{},
            display: false,
            visibleLeft: false,
        };
    },
    methods:{
        async getEmployeeSalaryChangesHistory() {
            this.loadingIndicator = true;
            let salaryChangesResponse = await this._employeeSalaryService.getEmployeeSalaryChanges(this.projectEmployeeId);
            if (salaryChangesResponse.isSuccess) {
                this.salaryHistory = salaryChangesResponse.data;
            }
            this.loadingIndicator = false;
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY HH:ss');
        },
        edit(item) {
            this.$router.push({ name: 'editProjectEmployee', params: { employeeId: item.employeeId, projectEmployeeId: item.id } });
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
    }
};
</script>
